@charset "UTF-8";
.expandableText__container--contracted {
  height: 100px;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative; }
  .expandableText__container--contracted:after {
    content: " ";
    background: linear-gradient(to top, white 0%, rgba(255, 255, 255, 0) 100%);
    height: 60px;
    position: absolute;
    top: 40px;
    width: 100%; }

.expandableText__container__block {
  color: #858585;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 10px; }
  .expandableText__container__block:last-child {
    margin-bottom: 0; }

.expandableText__container__block--scripture:after {
  content: '…';
  color: #858585;
  display: inline-block;
  text-align: center;
  width: 100%; }

.expandableText__container__block--scripture:last-child:after {
  display: none; }

.expandableText__container__block--scripture .verse-num,
.expandableText__container__block--scripture .chapter-num {
  font-size: 50%;
  font-weight: 400;
  line-height: 16px;
  position: relative;
  top: -5px; }

.expandableText__container__block--scripture .footnote {
  top: -.3em;
  margin-left: .1em; }

.expandableText__button {
  align-items: center;
  background: none;
  border: 0;
  color: #3a718b;
  display: inline-flex;
  font-weight: bold;
  letter-spacing: 1.5px;
  padding: 0;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase; }
  .expandableText__button svg {
    height: 12px;
    margin-left: 5px;
    width: 8px; }
