.landing {
  margin: 56px auto 92px;
  position: relative;
  max-width: 550px; }
  .landing__logo {
    display: block;
    margin: -30px auto 30px;
    max-width: 190px;
    padding: 0;
    width: 100%; }
