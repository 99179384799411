.biblePopup {
  background: rgba(58, 113, 139, 0.79);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  padding: 74px 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; }
  .biblePopup__iframe {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    width: calc(100vw - 34px);
    height: calc(100vh - 150px);
    margin-top: 16px; }
    @media (min-width: 800px) {
      .biblePopup__iframe {
        max-width: 500px;
        margin: 16px auto 0; } }
  .biblePopup__iframe__int {
    width: calc(100vw - 34px);
    height: calc(100vh - 150px); }
    @media (min-width: 800px) {
      .biblePopup__iframe__int {
        max-width: 500px; } }
