@import url("https://fonts.googleapis.com/css?family=Roboto:400,500");
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
body {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background-color: white;
  color: #212121;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  line-height: 1.7;
  margin-bottom: 1px;
  font-smoothing: antialiased;
}

img,
video {
  vertical-align: bottom;
}

p,
fieldset,
table,
ol,
ul,
dl,
iframe {
  margin: 0 0 10px;
}

p:last-child {
  margin: 0;
}

a {
  color: inherit;
}

hr {
  background-color: transparent;
  border-bottom: 1px solid #212121;
  clear: both;
  margin: 5px 0;
}

abbr,
acronym {
  border-bottom: 1px dotted grey;
}

em {
  font-style: italic;
}

small,
sub,
sup {
  font-size: 85%;
}

sub,
sup {
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.3em;
}

sup {
  top: -0.3em;
}

ol,
ul {
  list-style-position: outside;
}

ol {
  list-style-type: decimal;
}

ul {
  list-style-type: disc;
}

li {
  margin: 0 0 10px;
}
li:last-child {
  margin: 0;
}

textarea,
input,
button {
  font-family: "Roboto", sans-serif;
  outline: none;
}

.btn {
  background-color: white;
  border-radius: 2px;
  border: none;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
  color: #212121;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  height: 36px;
  line-height: 36px;
  outline: 0;
  overflow: hidden;
  padding: 0 2rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
}
.btn--primary {
  background-color: #3a718b;
  color: #fff;
}

* {
  box-sizing: border-box;
}

body {
  color: #212121;
  margin: 0;
  padding: 0;
}

.container {
  margin: 56px 0;
}

p {
  color: #858585;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

.big_title {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 16px;
}

.pre_title {
  color: #858585;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.dinky_title {
  color: #858585;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.dinky_text {
  color: #858585;
  font-size: 10px;
  line-height: 16px;
}

.action_text {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  color: #72a6bf;
  cursor: pointer;
  letter-spacing: 1.3px;
  line-height: 18px;
  text-decoration: none;
  transition: color 0.2s;
}
.action_text:hover {
  color: #3a718b;
}

.tablecloth {
  background: url(../img/tablecloth.jpg) repeat center center;
  height: 225px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}
.tablecloth--big {
  height: 300px;
}
