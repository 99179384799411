.coreNav {
  align-items: center;
  background: #ffffff;
  bottom: 0;
  box-shadow: 0px -2px 10px 2px rgba(0, 0, 0, 0.5);
  display: flex;
  height: 56px;
  justify-content: space-around;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 3;
}
.coreNav__item {
  border: 0;
  color: #757575;
  font-size: 12px;
  text-decoration: none;
  letter-spacing: 0.5px;
  display: inline-block;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5px;
}
.coreNav__item svg {
  display: block;
  width: 22px;
  max-height: 22px;
  margin-bottom: 1px;
}
.coreNav__item:hover {
  cursor: pointer;
}
.coreNav__item--active {
  color: #3a718b;
}
