@import url(https://fonts.googleapis.com/css?family=Roboto:400,500);
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
body {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background-color: white;
  color: #212121;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  line-height: 1.7;
  margin-bottom: 1px;
  font-smoothing: antialiased;
}

img,
video {
  vertical-align: bottom;
}

p,
fieldset,
table,
ol,
ul,
dl,
iframe {
  margin: 0 0 10px;
}

p:last-child {
  margin: 0;
}

a {
  color: inherit;
}

hr {
  background-color: transparent;
  border-bottom: 1px solid #212121;
  clear: both;
  margin: 5px 0;
}

abbr,
acronym {
  border-bottom: 1px dotted grey;
}

em {
  font-style: italic;
}

small,
sub,
sup {
  font-size: 85%;
}

sub,
sup {
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.3em;
}

sup {
  top: -0.3em;
}

ol,
ul {
  list-style-position: outside;
}

ol {
  list-style-type: decimal;
}

ul {
  list-style-type: disc;
}

li {
  margin: 0 0 10px;
}
li:last-child {
  margin: 0;
}

textarea,
input,
button {
  font-family: "Roboto", sans-serif;
  outline: none;
}

.btn {
  background-color: white;
  border-radius: 2px;
  border: none;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
  color: #212121;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  height: 36px;
  line-height: 36px;
  outline: 0;
  overflow: hidden;
  padding: 0 2rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
}
.btn--primary {
  background-color: #3a718b;
  color: #fff;
}

* {
  box-sizing: border-box;
}

body {
  color: #212121;
  margin: 0;
  padding: 0;
}

.container {
  margin: 56px 0;
}

p {
  color: #858585;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

.big_title {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 16px;
}

.pre_title {
  color: #858585;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.dinky_title {
  color: #858585;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.dinky_text {
  color: #858585;
  font-size: 10px;
  line-height: 16px;
}

.action_text {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  color: #72a6bf;
  cursor: pointer;
  letter-spacing: 1.3px;
  line-height: 18px;
  text-decoration: none;
  transition: color 0.2s;
}
.action_text:hover {
  color: #3a718b;
}

.tablecloth {
  background: url(data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAYGBgYHBgcICAcKCwoLCg8ODAwODxYQERAREBYiFRkVFRkVIh4kHhweJB42KiYmKjY+NDI0PkxERExfWl98fKcBBgYGBgcGBwgIBwoLCgsKDw4MDA4PFhAREBEQFiIVGRUVGRUiHiQeHB4kHjYqJiYqNj40MjQ+TERETF9aX3x8p//CABEIAGQAZAMBIgACEQEDEQH/xAAaAAADAAMBAAAAAAAAAAAAAAAAAQIDBAYF/9oACAEBAAAAAOiTYvc3ZTk5tFKfe21N4r5kFR7m3Bc3zEU1Pu7im4vmZGHubErIsvLzcB7u2ReOsKhzfQbyx0VzbkDo9oTRyYmPpNvEZIOSGN+/vyOZ5SWjJ7++leJcqkS+l2nIPkEqR0e9MucX/8QAGAEBAQEBAQAAAAAAAAAAAAAAAAUCAQT/2gAIAQIQAAAAuOeLJQc8PChx4smidgpE3JUJuSoTcd7/AP/EABkBAQEBAQEBAAAAAAAAAAAAAAAFAgEDBv/aAAgBAxAAAAD5ooaJpQ0TShox3lf0ckFjZELGyIWNn//EADAQAAEDAwIEBAYBBQAAAAAAAAEAAhEhMUEEEgMiUXEUYaGxBRMjMpGyM0JigdHx/9oACAEBAAE/AIgUQFAgJJ7qlaFGjbZTjVaKfDs7H3Kbu65CmgvMmU4ktFDhOaSQYTZBdyhOBGAge891ultxZN/qQnmTmxNRdUmwtRaMkabhVwfcrdGMjKc77UXfhEktnvlH7jf8okzhFCIQmb5RBRcU4jpXstHPhuH2OPMqTzDNFLqdSSpJHn2QBgiRnCcMz6KZ/wCIZEIN5VtExlECiLhNsou5bLRgnSstn3KlsuM5CNdpkXKJMXF+qGRTKcyuIQZ/chKIN5QRsJKAE0m6cRQQtET4Zn+fdAyTI9FaOyJB2jzQLg7OVM3K3TlCDNYQEgoBvunAUqhuwjdoWjA8KwEdc+a2mbiZoiyTiyLSQD5rN+t0WtvMqGoaPSmmz3XgtPH8ZxkoaTT830/Urwelg8mOpQ0en3D6eYucp2k0wcOQ5yVoNHo/DcP6Zscnqm6LSl235Rv1KGg0cj6R/JTtFpQJ+UbjJQ+H6Mn+I3OShoNIAPpk/leB0lPpepQI/C3UwK9UTlG18dVae6IK0LQdLw6YOfNbQOI6tz1TBavqnkdchGQ6JiSgwEkbhZERlNBRPKVUiK5UCPu9FUCAoklaCBpOGT0P7IRvcDN0Q4iBPmiDsvlOnc04lEkG3onHcZ5kBExaCp5DbCG0mwuUyjYICvMbRWi23JXw8N8Fw6dfdcu4wJM5RfANMlAja6gNQoZuERmiluQLQnOYDj8ra65JTQS0IGCsZqFJLjSxhNrIK+Hz4bhjbg/snADOZunCRdAwTIxK+4EwU7bO2oMIsaahxCa4nITHCBay3MJMqW7R/tHNBdEBpmFoAPCcMhuD+yIkE5C+XAEjAoiaGmK1RfJoKd0DDgdot1W1qNI7rhG/ZbyHOoLo/ZKmAYj7k55pay+HuPhWdz7riChPZOc75YM9U47WujMJvMQD1REI8R0lf//EACARAAEDBAIDAAAAAAAAAAAAAAIAASASMlJxAxMQIjH/2gAIAQIBAT8A8ncW5lcW5lc+4Vlk6rLJ1WWTo+Q6y9n+qs8nXYeTrsPJ4cl5bmd5bnyXluH/xAAhEQABAgYDAQEAAAAAAAAAAAACAyAAARMyUnESM3IQIv/aAAgBAwEBPwD6Fg6eFg6eFg6ZwDGUcAxlHAMZQmknTD8DbFJPAYpJ4DFNPEWJdYeXpdYeXp9YaZ//2Q==) repeat center center;
  height: 225px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}
.tablecloth--big {
  height: 300px;
}

.card {
  background-color: white;
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.27);
  border-radius: 2px;
  margin: 16px auto;
  width: calc(100% - 32px);
  max-width: 480px; }
  @media (min-width: 500px) {
    .card {
      max-width: none;
      max-width: initial; } }
  .card--pullUp {
    position: relative;
    margin-top: -25px; }
  .card header {
    height: 0;
    overflow: hidden;
    padding-bottom: 50%;
    position: relative;
    width: 100%; }
  .card header img {
    border-radius: 2px 2px 0 0;
    display: block;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%; }
  .card .pre_title {
    margin: 0; }
  .card .big_title {
    margin-bottom: 8px; }
  .card__body {
    padding: 16px; }

.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.27);
  margin: 16px auto;
  max-width: 480px;
  padding: 20px 10px;
  text-align: center;
  width: calc(100% - 32px); }

.loader__icon {
  animation: circle_rotate 1.4s linear infinite;
  height: 50px;
  margin: 0 auto;
  width: 50px; }

.loader__text {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  margin-top: 25px; }

.loader__svg {
  color: #3a718b;
  height: 50px;
  width: 50px; }

.loader__svg__circle {
  animation: dashed_circle 1.4s ease-in-out infinite;
  color: #3a718b;
  line-height: 1;
  stroke-dasharray: 80px, 200px;
  stroke-dashoffset: 0px;
  stroke: currentColor; }

@keyframes circle_rotate {
  100% {
    transform: rotate(360deg); } }

@keyframes dashed_circle {
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0px; }
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px; }
  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -120px; } }

.churchPickerPage {
  margin: 74px 0 24px; }

.churchPicker {
  background: #ffffff;
  color: #1a1a1a;
  font-size: 14px;
  margin: 16px auto;
  max-width: 480px;
  padding: 16px;
  width: calc(100% - 32px); }

.churchPicker__inputGroup {
  margin: 30px 0 0;
  position: relative; }

.churchPicker__label {
  color: #999;
  display: block;
  font-size: 18px;
  font-weight: normal;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 10px;
  transition: 0.2s ease all; }

.churchPicker__input {
  border: none;
  border-bottom: 1px solid #757575;
  display: block;
  font-size: 18px;
  padding: 10px 0;
  width: 100%; }
  .churchPicker__input:focus {
    outline: none; }

.churchPicker__input:focus ~ label,
.churchPicker__input:valid ~ label {
  color: #3a718b;
  font-size: 14px;
  top: -20px; }

.churchPicker__bar {
  display: block;
  position: relative;
  width: 100%; }

.churchPicker__bar:before,
.churchPicker__bar:after {
  background: #3a718b;
  bottom: 1px;
  content: "";
  height: 2px;
  position: absolute;
  transition: 0.2s ease all;
  width: 0; }

.churchPicker__bar:before {
  left: 50%; }

.churchPicker__bar:after {
  right: 50%; }

.churchPicker__input:focus ~ .churchPicker__bar:before,
.churchPicker__input:focus ~ .churchPicker__bar:after {
  width: 50%; }

.churchPicker__result {
  height: 0;
  position: relative;
  z-index: 2; }

.churchPicker__result__list {
  background-color: white;
  list-style-type: none;
  position: relative;
  top: 0; }

.churchPicker__result__item {
  align-items: center;
  color: #1a1a1a;
  display: flex;
  font-size: 14px;
  letter-spacing: 0.81px;
  margin: 0;
  padding: 10px;
  text-decoration: none;
  transition: background-color 0.2s; }
  .churchPicker__result__item:hover {
    background-color: #72a6bf; }
  .churchPicker__result__item img {
    background: #ccc;
    border-radius: 50%;
    height: 24px;
    margin-right: 10px;
    width: 24px; }

.landing {
  margin: 56px auto 92px;
  position: relative;
  max-width: 550px; }
  .landing__logo {
    display: block;
    margin: -30px auto 30px;
    max-width: 190px;
    padding: 0;
    width: 100%; }

.study {
  margin: 56px auto 72px;
  max-width: 550px;
  background-color: white;
  display: flex;
  flex-direction: column; }
  @media (min-width: 550px) {
    .study {
      box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.27);
      border-radius: 2px;
      margin: 84px auto 124px; } }
  .study__question {
    padding: 16px;
    margin-bottom: 32px; }
    @media (min-width: 550px) {
      .study__question {
        margin-bottom: 0; } }
  .study__icon {
    color: #858585;
    position: absolute;
    left: 20px;
    width: 20px;
    height: 20px;
    top: 20px; }
  .study__footer {
    background-color: #3a718b;
    bottom: 56px;
    display: flex;
    font-size: 10px;
    height: 50px;
    justify-content: space-between;
    left: 0;
    letter-spacing: 1px;
    padding: 16px;
    position: fixed;
    width: 100%; }
    .study__footer a {
      align-items: center;
      color: white;
      display: inline-flex;
      font-weight: bold;
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: 1.5px;
      padding: 0 5px; }
      .study__footer a:last-child {
        float: right; }
    .study__footer__icon {
      width: 12px;
      height: 16px;
      color: white; }
      .study__footer__icon--left {
        padding-right: 5px; }
      .study__footer__icon--right {
        padding-left: 5px; }
  .study__lead__image {
    position: relative;
    background-position: center center;
    background-size: cover;
    height: 190px;
    width: 100%; }
    .study__lead__image img {
      display: block;
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 100%; }
  .study__lead__icons {
    margin-top: -40px;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 2; }
    .study__lead__icons__icon {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      border: 3px solid white;
      background-color: #72a6bf;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center; }
      .study__lead__icons__icon svg {
        color: white;
        height: 35px;
        width: 40px; }
      .study__lead__icons__icon:first-child {
        margin-right: 10px; }

.study__introduction__section {
  border-bottom: 1px solid #e4e4e4;
  position: relative;
  padding: 16px;
  text-align: left; }
  .study__introduction__section:last-child {
    border: none; }
  .study__introduction__section--iconed {
    padding-left: 70px; }

.study__introduction__image {
  background-position: center center;
  background-size: cover;
  height: 190px;
  position: relative;
  width: 100%; }
  .study__introduction__image img {
    display: block;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%; }

.question {
  background-color: white; }
  .question__header {
    margin-bottom: 16px; }
    .question__header h2 {
      color: #212121;
      font-size: 24px;
      font-weight: 400; }
    .question__header span {
      color: #72a6bf;
      display: block;
      font-size: 14px;
      margin-bottom: 5px; }
  .question__subquestions {
    color: #72a6bf;
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    list-style-type: none; }
    .question__subquestions li {
      margin: 0;
      padding: 24px 0 0; }
    .question__subquestions li:first-child {
      padding-top: 0; }
  .question__scripture {
    border-top: 1px solid #858585;
    margin: 15px 0;
    padding-top: 15px; }
  .question--title-slide {
    margin-top: 0;
    padding: 32px 16px;
    text-align: center; }

.sermonList {
  text-decoration: none;
}
.sermonList a {
  text-decoration: none;
}
.sermonList__item {
  border-bottom: 1px solid #ccc;
  font-size: 14px;
  padding: 16px;
}
.sermonList__item__leadimage {
  margin-bottom: 16px;
  width: 100%;
}
.sermonList__item__body {
  width: 100%;
}
.sermonList__item__title {
  font-size: 16px;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.sermonList__item__date {
  display: block;
}
.sermonList__item__passage {
  color: #72a6bf;
}
.sermonList__item__star {
  align-items: center;
  color: #72a6bf;
  display: flex;
  padding: 0 16px;
}
.sermonList__item__star:hover {
  color: #446d81;
}

@charset "UTF-8";
.expandableText__container--contracted {
  height: 100px;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative; }
  .expandableText__container--contracted:after {
    content: " ";
    background: linear-gradient(to top, white 0%, rgba(255, 255, 255, 0) 100%);
    height: 60px;
    position: absolute;
    top: 40px;
    width: 100%; }

.expandableText__container__block {
  color: #858585;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 10px; }
  .expandableText__container__block:last-child {
    margin-bottom: 0; }

.expandableText__container__block--scripture:after {
  content: '…';
  color: #858585;
  display: inline-block;
  text-align: center;
  width: 100%; }

.expandableText__container__block--scripture:last-child:after {
  display: none; }

.expandableText__container__block--scripture .verse-num,
.expandableText__container__block--scripture .chapter-num {
  font-size: 50%;
  font-weight: 400;
  line-height: 16px;
  position: relative;
  top: -5px; }

.expandableText__container__block--scripture .footnote {
  top: -.3em;
  margin-left: .1em; }

.expandableText__button {
  align-items: center;
  background: none;
  border: 0;
  color: #3a718b;
  display: inline-flex;
  font-weight: bold;
  letter-spacing: 1.5px;
  padding: 0;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase; }
  .expandableText__button svg {
    height: 12px;
    margin-left: 5px;
    width: 8px; }

.biblePopup {
  background: rgba(58, 113, 139, 0.79);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  padding: 74px 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; }
  .biblePopup__iframe {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    width: calc(100vw - 34px);
    height: calc(100vh - 150px);
    margin-top: 16px; }
    @media (min-width: 800px) {
      .biblePopup__iframe {
        max-width: 500px;
        margin: 16px auto 0; } }
  .biblePopup__iframe__int {
    width: calc(100vw - 34px);
    height: calc(100vh - 150px); }
    @media (min-width: 800px) {
      .biblePopup__iframe__int {
        max-width: 500px; } }

.studyHeader {
  align-items: center;
  background-color: rgba(58, 113, 139, 0.8);
  color: white;
  display: flex;
  font-size: 12px;
  height: 40px;
  justify-content: space-between;
  padding: 5px 16px;
  width: 100%; }
  .studyHeader__title {
    max-width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
    @media (min-width: 400px) {
      .studyHeader__title {
        max-width: 75%; } }
  .studyHeader__passage {
    background: no-repeat;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 12px;
    text-decoration: underline; }
    .studyHeader__passage:hover {
      text-decoration: none; }

.coreHeader {
  align-items: center;
  background-color: #72a6bf;
  box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  font-size: 21px;
  line-height: 24px;
  overflow: hidden;
  padding: 16px;
  position: fixed;
  text-overflow: ellipsis;
  top: 0;
  white-space: nowrap;
  width: 100%;
  z-index: 3; }
  .coreHeader__back {
    background-color: transparent;
    border: 0;
    display: flex;
    margin: 0 16px 0 0;
    padding: 0; }
    .coreHeader__back__icon {
      color: white;
      height: 24px;
      width: 24px; }

.coreNav {
  align-items: center;
  background: #ffffff;
  bottom: 0;
  box-shadow: 0px -2px 10px 2px rgba(0, 0, 0, 0.5);
  display: flex;
  height: 56px;
  justify-content: space-around;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 3;
}
.coreNav__item {
  border: 0;
  color: #757575;
  font-size: 12px;
  text-decoration: none;
  letter-spacing: 0.5px;
  display: inline-block;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5px;
}
.coreNav__item svg {
  display: block;
  width: 22px;
  max-height: 22px;
  margin-bottom: 1px;
}
.coreNav__item:hover {
  cursor: pointer;
}
.coreNav__item--active {
  color: #3a718b;
}

