.coreHeader {
  align-items: center;
  background-color: #72a6bf;
  box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  font-size: 21px;
  line-height: 24px;
  overflow: hidden;
  padding: 16px;
  position: fixed;
  text-overflow: ellipsis;
  top: 0;
  white-space: nowrap;
  width: 100%;
  z-index: 3; }
  .coreHeader__back {
    background-color: transparent;
    border: 0;
    display: flex;
    margin: 0 16px 0 0;
    padding: 0; }
    .coreHeader__back__icon {
      color: white;
      height: 24px;
      width: 24px; }
